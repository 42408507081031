import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { authVeriry } from '../Auth/Auth';
import './Dashboard.css'
import Notice from './Notice/Notice';
import { AddUser } from './AddUser/AddUser';

const Dashboard = (props) => {
  const newpage = props.page.name;
  const navigate = useNavigate();

  useEffect(()=>{
    authVeriry((res)=>{
      if(res !== true){
        navigate('/log')
      } 
    })
  })

  const logOut = ()=>{
    localStorage.clear()
    navigate('/log')
  }

  return (
    <div className='bgDark'>
      <div className='navBar bg-blueLight'>
            <Link to="/dashboard">Home</Link>
            <Link to="/dashboard/notice">Notice</Link>
            <Link to="/dashboard/addUser">Add User</Link>
            <button onClick={logOut} style={{color:'white', paddingRight:'10px'}}>Log Out</button>
      </div>
      <div>
        {newpage==='Notice'? <Notice /> : ''}
        {newpage==='addUser'? <AddUser /> : ''}
      </div>
    </div>
  )
}

export default Dashboard
