import React, { useEffect, useState } from 'react'
import './Header.css';
import AOS from 'aos';
import Wave from 'react-wavify'
import { Outlet, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { LoadingPage } from '../LoadingPage/LoadingPage';

const scroll = ()=>{
    window.scroll({
        top: 0,
        left: 0, 
        behavior: 'smooth',
      });
}


const Header = (props) => {
    const [loading, setLoading] = useState(false)
    const activity = props.result.activity;
    const [headerClassName, setHeaderClassName] = useState(false);
    const handlerScroll = ()=> {
      const navId = document.getElementById('navId');
        //document.getElementById("navId").classList.add("show-example");
        if(window.pageYOffset >= 100){
            document.getElementById("navId").classList.add("scroll-active");
        }else{
            document.getElementById("navId").classList.remove("scroll-active");
        }
    }

    useEffect(()=>{
        setLoading(true)
        if(activity === 'true'){
            scroll();
        }
        window.onscroll = () => handlerScroll();
        AOS.init();
        setTimeout(()=>{
            setLoading(false)
        }, 2000)
    }, [])

  return (
    <div>
        
        {
            loading?<LoadingPage />:''
        }
      

    <header>
        <div className="overlay"></div>

        <nav className='nav fixed-top'>

            <div className="container-fluid" id='navId'>
                <input type="checkbox" className='checkbox' id="checkbox"/>

                <div className="logo">
                    <img src="./photos/logo.png" alt="" />
                </div>

                <div className="navbars" id="">
                    <div className="res-logo">
                        <img src="./photos/logo.png" alt="" />
                        <p>Panchpir Degree College
                            <br/>
                            Mobile: 01714-762967
                        </p>
                    </div>
                    <nav>
                    <ul className="navbar">
                        <li className="navitem ">
                            <a className="navlink" href={`${window.location.origin.toString()}`}>HOME</a>
                        </li>
                       
                        <li className="navitem ">
                            <Link className="navlink" to="/admission">ADMISSION</Link>
                        </li>
						
						 <li className="navitem ">
                            <a className="navlink" >NOTICE <FontAwesomeIcon icon={faCaretDown} style={{fontSize:'20px', paddingLeft:'5px'}}/></a>
                            <div className='dropdown'>
                               <Link to="/notice" state={{section:"hsc"}} className='droplink'>H.S.C</Link>
                               <Link to="/notice" state={{section:"degree"}} className='droplink'>Degree</Link>
                               <Link to="/notice" state={{section:"honours"}} className='droplink'>Honours</Link>
                            </div>
                        </li>
						
						 <li className="navitem ">
                            <Link className="navlink" to="/gallery">GALLERY</Link>
                        </li>
					
						
						 <li className="navitem ">
                            <Link className="navlink" to="/contact">CONTUCT US</Link>
                        </li>
						
						<div className="  d-none d-lg-block ">
						</div>
					
					
                    </ul>  
                    </nav>
                                    
                </div>

                <div className="bar">
                    <div className="bar-item"></div>
                    
                </div>
                
            </div>

        </nav>
  
        <div id="intro" className="view">

            <div className="mask rgba-black-strong">

                <div className="container-fluid d-flex align-items-center justify-content-center h-100">

                    <div className="row d-flex justify-content-center text-center">

                        <div className="col-md-12">

                            <img src='./photos/logo.png' alt="" className='bodyLogo'/>
                            <h2 className="display-4 font-weight-bold mb-2" data-aos="fade-left" data-aos-duration="1000">
                                Panchpir Degree College
                            </h2>

                           
                          
							<div data-aos="fade-right" data-aos-duration="1000">
                               
                            <h4 className="  my-4">
								Panchpir Bazar, Ulipur, Kurigram.
							</h4>
							<a className="about_us_btn">About Us</a>
                                <br/>
                                <br/>
							<span className="h2 add" >Admission Going On</span>
							</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div className="wav path-0">
       
        <Wave fill='#ffffff'
            paused={false}
            style={{ display: 'flex' }}
            options={{
            height: 90,
            amplitude: 50,
            speed: 0.15,
            points: 3
            }}
        />
       
        </div>
        
    </header>
   
    </div>
  )
}

export default Header
