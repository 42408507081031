import React from 'react'
import './Slider.css'
import { Col, Container, Row } from 'react-bootstrap'
import {Notice} from './Notice'

import Carousel from 'react-bootstrap/Carousel';



export const Slider = () => {
  return (
    <div>
        <Container className='mt-5'>
            <Row>
                <Col md={8}>
                    <div className='protHeader mt-3'>
                        News & Events
                    </div>
                    <div className='carousel'>
                        <Carousel>
                            <Carousel.Item interval={3000}>
                                <img src="./carousel/1.jpg" className='carouselImg' alt='image' />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                                
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                                <img src="./carousel/2.jpg" className='carouselImg' alt='image' />
                                <Carousel.Caption>
                                <h3></h3>
                                <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item interval={3000}>
                                <img src="./carousel/3.jpg" className='carouselImg' alt='image' />
                                <Carousel.Caption>
                                <h3></h3>
                                <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item interval={3000}>
                                <img src="./carousel/4.jpg" className='carouselImg' alt='image' />
                                <Carousel.Caption>
                                <h3></h3>
                                <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item interval={3000}>
                                <img src="./carousel/5.jpg" className='carouselImg' alt='image' />
                                <Carousel.Caption>
                                <h3></h3>
                                <p></p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </Col>
                <Col md={4}>
                    <div className='protHeader mt-3'>
                        Recent Notice
                    </div>
                    <Notice />
                </Col>
            </Row>
        </Container>

    </div>
  )
}
