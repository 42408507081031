import React, { useEffect } from 'react'
import './Admission.css'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons"
import Aos from 'aos'

const scroll = ()=>{
    window.scroll({
        top: 700,
        left: 0, 
        behavior: 'smooth',
      });
}

const Admission = (props) => {
    const activity = props.result.activity;
    useEffect(()=>{
        Aos.init();
        if(activity === 'true'){
            scroll();
        }
        
    });
    
  return (
    <div className="admission_div text-center py-5">
        <h4 className='pb-5'>ভর্তি সংক্রান্ত প্রয়োজনীয় তথ্যঃ</h4>
        <Container>
            <Row>
                <Col md={4} sm={12}>
                
                    <Card className='card my-2' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" data-aos-delay="100">
                        <Card.Body>
                            <Card.Title>ব্যাচঃ এইচ.এস.সি</Card.Title>
                        </Card.Body>
                    </Card>


                    <Card className='card' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" data-aos-delay="100">
                        <Card.Body>
                            <Card.Title>প্রয়োজনীয় কাগজ পত্রাদিঃ</Card.Title>
                            <Card.Text className='add_list'>
                                <ul>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> কলেজ নির্ধারিত ভর্তি ফরম এ আবেদন করতে হবে। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এসএসসি / সমমান পাশের মূল নম্বরপত্র, প্রশংসা পত্র, প্রবেশপত্র ও রেজিস্ট্রেশন কার্ড। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এসএসসি / সমমান পাশের মূল নম্বরপত্র, প্রশংসা পত্র, প্রবেশপত্র ও রেজিস্ট্রেশন কার্ড এর ফটোকপি ০৪ খানা করে। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> পাসপোর্ট সাইজের রঙিন ছবি ০৪ খানা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> স্ট্যাম্প সাইজের রঙিন ছবি ০৪ খানা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> ছাত্র-ছাত্রীদের জন্ম নিবন্ধনের ফটোকপি ০১ খানা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> পিতা ও মাতার আইডি কার্ডের ফটোকপি ০১ খানা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এরা ছাড়াও ভর্তি সংক্রান্ত তথ্যাদি জানার জন্য পত্র কলেজের কার্যালয়ের যোগাযোগ করার জন্য বলা হইলো। </li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>


                </Col>

                <Col md={4} sm={12}>
                
                    <Card className='card my-2' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000" data-aos-delay="300">
                        <Card.Body>
                            <Card.Title>ব্যাচঃ ডিগ্রী</Card.Title>
                        </Card.Body>
                    </Card>


                    <Card className='card' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000" data-aos-delay="500">
                        <Card.Body>
                            <Card.Title>প্রয়োজনীয় কাগজ পত্রাদিঃ</Card.Title>
                            <Card.Text className='add_list'>
                                <ul>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> কলেজ নির্ধারিত ভর্তি ফরম এ আবেদন করতে হবে। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এসএসসি / সমমান পাশের মূল নম্বরপত্র, প্রশংসা পত্র, প্রবেশপত্র ও রেজিস্ট্রেশন কার্ড। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এসএসসি / সমমান পাশের মূল নম্বরপত্র, প্রশংসা পত্র, প্রবেশপত্র ও রেজিস্ট্রেশন কার্ড এর ফটোকপি ০৪ খানা করে।</li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> পাসপোর্ট সাইজের রঙিন ছবি ০৪ খানা।</li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> স্ট্যাম্প সাইজের রঙিন ছবি ০৪ খানা।</li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> ছাত্র-ছাত্রীদের জন্ম নিবন্ধনের ফটোকপি ০১ খানা।</li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> পিতা ও মাতার আইডি কার্ডের ফটোকপি ০১ খানা।</li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এরা ছাড়াও ভর্তি সংক্রান্ত তথ্যাদি জানার জন্য পত্র কলেজের কার্যালয়ের যোগাযোগ করার জন্য বলা হইলো।</li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>


                </Col>
               
                <Col md={4} sm={12}>
                
                    <Card className='card my-2' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000" data-aos-delay="700">
                        <Card.Body>
                            <Card.Title>ব্যাচঃ অনার্স </Card.Title>
                        </Card.Body>
                    </Card>


                    <Card className='card' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="3000" data-aos-delay="900">
                        <Card.Body>
                            <Card.Title>প্রয়োজনীয় কাগজ পত্রাদিঃ</Card.Title>
                            <Card.Text className='add_list'>
                                <ul>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> কলেজ নির্ধারিত ভর্তি ফরম এ আবেদন করতে হবে। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এইসএসসি / সমমান পাশের মূল নম্বরপত্র, প্রশংসা পত্র, প্রবেশপত্র ও রেজিস্ট্রেশন কার্ড এবং ফটোকপি ০৪ খানা করে। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এসএসসি / সমমান পাশের নম্বরপত্র, প্রশংসা পত্র, প্রবেশপত্র ও রেজিস্ট্রেশন কার্ড এবং ফটোকপি ০৪ খানা করে। (মূল কপির প্রয়োজন নেই)</li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> পাসপোর্ট সাইজের রঙিন ছবি ০৪ খানা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> স্ট্যাম্প সাইজের রঙিন ছবি ০৪ খানা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> ছাত্র-ছাত্রীদের জন্ম নিবন্ধনের ফটোকপি ০১ খানা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> পিতা ও মাতার আইডি কার্ডের ফটোকপি ০১ খানা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> কাগজপত্র সংরক্ষণের জন্য A4 সাইজের খাম/ফাইল ০১টি। </li>
                                </ul>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={12}>
                <Card className='card my-2' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" data-aos-delay="100">
                        <Card.Body>
                            <Card.Title>বিঃ দ্রঃ সকল কাগজপত্র ও ছবি সত্যায়িত ছাড়া জমা দিতে হইবে।</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container>

    </div>
  )
}

export default Admission
