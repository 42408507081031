import React from 'react'
import './Principal.css'
import { Container, Row, Col } from 'react-bootstrap'
export const Principal = () => {
  return (
    <div className='my-5'>
        <Container>
            <Row>
                <Col md={3}>
                    <div className='photoSection my-1'>
                        <img src="./photos/principal.png" alt="Principal Photo" />
                       <i>Prof. Late Nurul Amin Sarkar</i>
                        <span style={{fontWeight:'bolder', paddingBottom:'10px'}}>Principal</span>
                    </div>
                </Col>

                <Col md={9} >
                    <div className='baniSection my-1'>
                    <span style={{fontWeight:'bold', color:'rgb(16 16 72)'}}>অধ্যক্ষ মহোদয়ের বাণী</span>
                    <p className='my-2 principal_speach'>
                        প্রিয় শিক্ষার্থীরা,
                        <br/>
                        আপনারা আজ যে শিক্ষা প্রতিষ্ঠানে অবস্থান করছেন, তা শুধুমাত্র জ্ঞান অর্জনের স্থান নয়, বরং এটি আপনার ব্যক্তিত্ব গঠনের একটি গুরুত্বপূর্ণ ক্ষেত্র। শিক্ষা আপনাদেরকে নতুন চিন্তা, সৃজনশীলতা এবং নৈতিক মূল্যবোধের দিকে পরিচালিত করে।
                        আমরা জানি, কঠোর পরিশ্রম এবং অধ্যবসায়ই সফলতার মূল চাবিকাঠি। তাই, আপনাদের উচিত প্রতিটি মুহূর্তকে মূল্যবান করে তোলা এবং নিজেদের দক্ষতা ও জ্ঞানের পরিধি বাড়ানো।
                        আপনারা আগামী দিনের সমাজের নেতৃত্ব দেবেন, তাই নিজেদের প্রস্তুত করুন। নতুন নতুন ধারণা গ্রহণ করুন, মানবতার কল্যাণে কাজ করুন এবং সমাজের উন্নয়নে অবদান রাখুন।
                        শিক্ষা জীবনের একটি অমূল্য সম্পদ, তাই এই সময়টিকে সঠিকভাবে কাজে লাগান।
                        শুভকামনা রইল।
                                    
                    </p>
                    <button className='mybtn'>+ Read More</button>
                    </div>
                    
                </Col>
            </Row>
        </Container>

    </div>
  )
}
